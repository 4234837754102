import React from 'react'
import About from './About'
import AboutDesc from './AboutDesc'
export default function AboutF() {
  return (
    <div>
        <About/>
        <AboutDesc/>
    </div>
  )
}
